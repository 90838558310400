const parse = (uaStr)=>{
    let msg = ""
    var agent = {
        platform: {},
        browser: {},
        engine: {},
        uaStr:uaStr,
        isInApp : (uaStr)=>{
            let result = false
            if ((uaStr.indexOf("FBAN") > -1) || (uaStr.indexOf("FBAV") > -1)) result = true
            else if (uaStr.indexOf("Line") > -1) result = true
            return result
        },
        getExternalBrowser : (platform,targetUrl)=>{
            //     Google Chrome: googlechrome://domain
            
            // Safari: safari-https://domain
            
            // Firefox: firefox://open-url?url=domain
            
            // Opera: opera-https://domain
            
            // X Web: x-web-search://?xxx
            let url = targetUrl.replace("https://","").replace("http://","")
            if (platform.mac || platform.iphone || platform.ipad) {
                alert("因Google安全政策，需跳轉至外部瀏覽器。若系統未能自動跳轉，請手動轉移至外部瀏覽器使用本系統。")
            }
            return `googlechrome://${url}`;

        }
            
    };

    var ua = uaStr,
        p = agent.platform,
        b = agent.browser,
        e = agent.engine;
    try {
         // detect platform
        if (/Windows/.test(ua)) {
            p.name = 'win';
            p.win = true;
        }else if (/Linux/.test(ua)) {
            p.name = 'linux';
            p.linux = true;
        } else if (/iPhone|iPod/.test(ua)) {
            p.name = 'iphone';
            p.iphone = true;
        } else if (/iPad/.test(ua)) {
            p.name = 'ipad';
            p.ipad = true;
        } else if (/Android/.test(ua)) {
            p.name = 'android';
            p.android = true;
        } else if (/Mac/.test(ua)) {
            p.name = 'mac';
            p.mac = true;
        } else {
            p.name = 'other';
            p.unknown = true;
        }
    } catch (error) {
        msg = error.message
    }
    try{
        // detect browser
        if (/MSIE/.test(ua)) {
            b.name = 'msie';
            b.msie = true;
        } else if (/Firefox/.test(ua)) {
            b.name = 'firefox';
            b.firefox = true;
        } else if (/Chrome/.test(ua)) { // must be tested before Safari
            b.name = 'chrome';
            b.chrome = true;
        } else if (/Line/.test(ua)) {
            b.name = 'line';
            b.line = true;
        }  else if (/Safari/.test(ua)) {
            b.name = 'safari';
            b.safari = true;
        } else if (/Opera/.test(ua)) {
            b.name = 'opera';
            b.opera = true;
        } else {
            b.name = 'other';
            b.unknown = true;
        }
    } catch (error) {
        msg = error.message
    }
    try{
        // detect browser version
        if (b.msie) {
            b.version = /MSIE (\d+(\.\d+)*)/.exec(ua)[1];
        } else if (b.firefox) {
            b.version = /Firefox\/(\d+(\.\d+)*)/.exec(ua)[1];
        } else if (b.chrome) {
            b.version = /Chrome\/(\d+(\.\d+)*)/.exec(ua)[1];
        } else if (b.line) {
            b.version = /Line\/(\d+(\.\d+)*)/.exec(ua)[1];
        }  else if (b.safari) {
            try {
                b.version = /Version\/(\d+(\.\d+)*)/.exec(ua)[1];
            } catch (error) {
                b.version = /Safari\/(\d+(\.\d+)*)/.exec(ua)[1];
            }
        } else if (b.opera) {
            b.version = /Version\/(\d+(\.\d+)*)/.exec(ua)[1];
        } else {
            b.version = 0;
        }
    } catch (error) {
        msg = error.message
    }
    try{
        // detect engine
        if (/Trident/.test(ua) || b.msie) {
            e.name = 'trident';
            e.trident = true;
        } else if (/WebKit/.test(ua)) { // must be tested before Gecko
            e.name = 'webkit';
            e.webkit = true;
        } else if (/Gecko/.test(ua)) {
            e.name = 'gecko';
            e.gecko = true;
        } else if (/Presto/.test(ua)) {
            e.name = 'presto';
            e.presto = true;
        } else {
            e.name = 'other';
            e.unknown = true;
        }
    } catch (error) {
        msg = error.message
    }
    try{
        // detect engine version
        if (e.trident) {
            e.version = /Trident/.test(ua)? /Trident\/(\d+(\.\d+)*)/.exec(ua)[1]: 0;
        } else if (e.gecko) {
            e.version = /rv:(\d+(\.\d+)*)/.exec(ua)[1];
        } else if (e.webkit) {
            e.version = /WebKit\/(\d+(\.\d+)*)/.exec(ua)[1];
        } else if (e.presto) {
            e.version = /Presto\/(\d+(\.\d+)*)/.exec(ua)[1];
        } else {
            e.version = 0;
        }
    } catch (error) {
        msg = error.message
    }
   
    return agent;
}

exports.parse = parse;