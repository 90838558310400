export default {
    en: {
        validations: {
            required: "The field {property} is required.",
            minLength: "The {property} field has a value of '{model}', but it must have a min length of {min}.",
            maxLength: "The maximum length allowed is {max}.",
            minValue:  "The minimum value allowed is {max}.",
            sameAs : "The value must be equal to the ${otherName} value.",
            hasUppercase:"Must contain uppercase character.",
            hasLowercase:"Must contain lowercase character.",
            hasSpecial:"Must contain special character #?!@$%^&*-",
            hasNumber:"Must contain number.",
        }
    },
    tw: {
        validations: {
            required: "欄位必填",
            email:"Email格式不符",
            phone:"電話格式不符",
            hasUppercase:"必須包含英文大寫字母",
            hasLowercase:"必須包含英文小寫字母",
            hasSpecial:"必須包含特殊符號 #?!@$%^&*-",
            hasNumber:"必須包含數字",
            mobile:"手機格式不符09xxxxxxxx",
            alphaNum:"只允許數字與英文字母",
            alpha:"只允許英文字母",
            numeric:"只允許數字",
            minLength: "欄位長度至少需要 {min}",
            maxLength: "欄位長度不可超過 {max}",
            minValue:  "數值不可小於 {min}",
            maxValue: "數值不可大於 {max}",
            sameAs : "欄位值必須等於 {equalTo}",
            integer: "必須為整數",
            minDate: "日期不可早於 {min}",
            maxDate: "日期不可晚於 {max}",
            minEqualDate:"日期不可早於 {min}",
            maxEqualDate:"日期不可晚於 {max}",
            contains:"資料值必須包含其一： {param}",
            matches:"資料值必須完全符合： {param}",

        }
    }
}