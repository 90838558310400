import { createRouter, createWebHistory } from 'vue-router';
import routeEvent from './modules/routeEvent';
import routeDonate from './modules/routeDonate';
import routeAdmin from './modules/routeAdmin';
import routeLogin from './modules/routeLogin';
import routeCompany from './modules/routeCompany';
import routeMember from './modules/routeMember';
import routeGroup from './modules/routeGroup';
import routeActivity from './modules/routeActivity';
import routeSmartTool from './modules/routeSmartTool';
import routeAI from './modules/routeAI';
import routePayment from './modules/routePayment';
import routeAccount from './modules/routeAccount';

import AppLayout from '@/components/layouts/AppLayout.vue';
const router = createRouter({
    history: createWebHistory(),
   
    routes: [
        ...routeLogin,
        ...routeSmartTool,
        ...routeAI,
        ...routePayment,

        {
            path: '/',
            name:'/',
            component: AppLayout,
            redirect:'/manageEvent/list',
            meta:{
                
                seo:{
                    title:""
                }
            },
            children:[
                ...routeEvent,
                ...routeDonate,
                ...routeAdmin,
                ...routeCompany,
                ...routeMember,
                ...routeGroup,
                ...routeActivity,
                ...routeAccount,
                {
                    path: '/center',
                    name:'center',
                    component: () => import('@/views/others/ControlCenterPage.vue'),
                    meta: { 
                        requiresAuth: true ,
                    },
                },
               
                {
                    path: '/notapply',
                    name: 'notapply',
                    component: () => import('@/views/others/NotYetApplyPage.vue'),
                    meta: { 
                        requiresAuth: false ,
                    },
                },
                {
                    path: '/contact',
                    name: 'contact',
                    component: () => import('@/views/others/Contact.vue'),
                    meta: { 
                        requiresAuth: false ,
                    },
                },
               
            ]
          
        },
        {
            path: '/home',
            name:'home',
            component: AppLayout,
            redirect:'/center',
        },
        {
            path: '/permissiondeny',
            name: 'permissiondeny',
            component: () => import('@/views/others/PermissionDenyPage.vue'),
            meta: { 
                requiresAuth: false ,
            },
        },
        {
            path: '/plandeny',
            name: 'plandeny',
            component: () => import('@/views/others/PlanDenyPage.vue'),
            meta: { 
                requiresAuth: false ,
            },
        },
        {
            path: "/:catchAll(.*)",
            component: () => import('@/views/others/404Page.vue'),
        },
    ]
});
import { useUserStore } from '@/stores/user'
import { useLayout } from '@/components/composables/layout'; 
import { useCompanyStore } from '@/stores/company'
import { useSmartToolStore } from '@/stores/smarttool'
const whiteList = ['/redeem-auth-link','/event-redeem-auth-link','/logout','/login_return','/register','/forgot-password']
function isInWhiteList(path){
    return  whiteList.indexOf(path)>=0;
}
// beforeResolve,beforeEach

router.beforeEach( (to, from, next) => {
    const storeCompany = useCompanyStore()
    const store = useUserStore()
    const storeSmartTool = useSmartToolStore()
    const { asyncDone,openDialogBox,setBlockFullScreen } = useLayout();
    const url_apply = `${process.env.VUE_APP_USER_HOST}/company/apply`
    const url_landing = `${process.env.VUE_APP_USER_HOST}/landing`
    const _showUpgrade= ()=>{
        setTimeout(()=>{
            openDialogBox({
                show: true,
                title:'立即升級方案',
                message:"您的組織尚未開啟此功能，您可以聯繫我們立即升級，為您的組織加入新功能！",
                icon:"",
                btnCancelShow:true,
                btnConfirmText:"我想升級，聯繫客服",
                btnCancelText:"再等等",
                btnConfirmEvent:()=>{
                    location.href = "/contact"
                },
                btnCancelEvent:()=>{
                    location.href = "/center"
                }
            
            })
        },500)
    }
    const _showCompanyApply= ()=>{
        setTimeout(()=>{
            openDialogBox({
                show: true,
                title:'未經授權的組織',
                message:`只有審查通過的組織與組織管理者可以使用本系統後台，您尚未有任何組織，請先填寫申請表，若已經填寫，請耐心等候審查。`,
                icon:"warning",
                btnConfirmText:"立即申請",
                btnCancelShow:true,
                btnCancelText:`查詢申請狀態`,
                btnConfirmEvent:()=>{
                    location.href = url_apply
                },
                btnCancelEvent:()=>{
                    location.href = "/manageCompany/myapplies"
                }
            
            })
        },1000)
    }
    const _initAuth = ()=>{
        setBlockFullScreen(false)
        
        if(store.user.is_verified==false){
            next( { name: 'verify' })
        }else if(!storeCompany.selected_company.id){
            _showCompanyApply()
        }else if(to.meta.function && !storeCompany.canFunction(to.meta.function)){
            _showUpgrade()
            
        }else if(to.meta.permissions && !store.canAction(to.meta.permissions)){
            next( { name: 'permissiondeny' })
        }else if(to.meta.validPlanStatus && !storeCompany.validatePlan(to.meta.validPlanStatus)){
            next( { name: 'plandeny' })
        }else{
            next()
        }
       
    }
     
    if(!isInWhiteList(to.path)){
        setBlockFullScreen(true)
    }

    if(to.matched.some(record => record.meta.requiresAuth)){
        store.setWhereiwas({fullpath:to.fullPath,params:to.params,query:to.query})
        if(to.path.indexOf('/smartTool')>=0){
            //smart tool scenario
        
            store.getMyProfile().then((data)=>{
                setBlockFullScreen(false)
                if(store.user.is_verified==false){
                    next( { name: 'verify' })
                }else if((!storeSmartTool.selected_data.data_id || !storeSmartTool.selected_company.company_id) && to.path.indexOf('/smartTool/events')<0){
                    next( { name: 'smartTool/events' })
                }else if(to.meta.permissions && !store.canAction(to.meta.permissions)){
                    next( { name: 'permissiondeny' })
                }else{
                    next()
                }
            })
           
        }else if(to.meta.ignoreCompany){
            //不需選擇組織，只要登入就可以進入的頁面
            //manageCompany/myapplies scenario
        
            store.getMyProfile().then((data)=>{
                setBlockFullScreen(false)
                if(store.user.is_verified==false){
                    next( { name: 'verify' })
                }else{
                    next()
                }
            })
           
        }else{
            //normal scenario
            storeCompany.getMyCompanies().then((data)=>{
                if(data.status){
                    store.setUserProfile(data.user)
                    _initAuth()
                }else if(data.code == 10){
                    _showCompanyApply()
                }else{
                    next( { name: 'login' })
                }
            
            })
        }

       
       

        

        
    }else{
        setBlockFullScreen(false)
        next()
    }
   
});
export default router;
