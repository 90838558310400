

export default [
    {
        path: '/aiTool',
        name: 'aiTool',
        component: () => import('@/components/layouts/aiTool/AIToolLayout.vue'),
        redirect:'/aiTool/video/list',
        props: true,
        meta: { 
            requiresAuth: false ,
            seo:{
                title:"AI小編",
            }
        },
        children: [
            
            // {
            //     path: 'points/buy',
            //     name: 'aiTool/points/buy',
            //     component: () => import('@/views/smartTool/aiTool/PointsPurchasePage.vue'),
            //     meta: { 
            //         requiresAuth: false ,
            //         permissions:[],
            //         seo:{
            //             title:"購買點數",
            //         }
            //     },
            // },
            { 
                path: 'billings',
                name: 'aiTool/billings',
                component: () => import('@/views/smartTool/aiTool/PointsBillingPage.vue'),
                meta: { 
                    requiresAuth: false ,
                    permissions:[],
                    seo:{
                        title:"點數付款紀錄",
                    }
                },
            },
            { 
                path: 'points/usage',
                name: 'aiTool/points/usage',
                component: () => import('@/views/smartTool/aiTool/PointsUsagePage.vue'),
                meta: { 
                    requiresAuth: false ,
                    permissions:[],
                    seo:{
                        title:"用量查詢",
                    }
                },
            },
            { 
                path: 'video/upload',
                name: 'aiTool/video/upload',
                component: () => import('@/views/smartTool/aiTool/VideoUploadPage.vue'),
                meta: { 
                    requiresAuth: false ,
                    permissions:[],
                    seo:{
                        title:"上傳影片",
                        hide:true
                    }
                },
            },
            { 
                path: 'video/list',
                name: 'aiTool/video/list',
                component: () => import('@/views/smartTool/aiTool/VideoListPage.vue'),
                meta: { 
                    requiresAuth: false ,
                    permissions:[],
                    seo:{
                        title:"影片列表",
                    }
                },
            },
            { 
                path: 'video/detail/:video_request_id',
                name: 'aiTool/video/detail',
                props:true,
                component: () => import('@/views/smartTool/aiTool/VideoDetailPage.vue'),
                meta: { 
                    requiresAuth: true ,
                    // function:'',
                    // permissions:[],
                    
                    seo:{
                        title:"影片詳細資料",
                    }
                },
            },
           
        ]
    },
   
];